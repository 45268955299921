import * as React from "react";

import { FormOptionsSection } from "../FormOptionsSection/FormOptionsSection";
import { FormOptionsContainer, StyledImg } from "./FormOptions.styled";

import { Align, Title } from "../../UI";

import { defaultTheme } from "../../../styles/theme";
import { useText } from "../../../common/hooks/useText";

const minus = require("../../../assets/images/minus.svg");
const plus = require("../../../assets/images/plus.svg");

interface FormOptionsProps {
  optionTypes: {
    title: string;
    options: {
      inputType: string;
      name: string;
      id: string;
      label: string;
      value: string | number;
    }[];
  }[];
  direction: "row" | "column";
}

export const FormOptions = ({ optionTypes, direction }: FormOptionsProps) => {
  const { text } = useText();
  const [open, setOpen] = React.useState(true);
  return (
    <FormOptionsContainer>
      <Align
        direction="row"
        align="center"
        justify="space-between"
        onClick={() => setOpen(!open)}
      >
        <Title size="medium" color={defaultTheme.colors.accent} bold>
          {text("contact.formOptions.title")}
        </Title>
        <StyledImg src={open ? minus : plus} alt="more" />
      </Align>
      {open && (
        <Align direction={direction} align="flex-start" justify="center">
          {optionTypes.map((optionType, index) => (
            <FormOptionsSection
              key={index}
              title={optionType.title}
              options={optionType.options}
            />
          ))}
        </Align>
      )}
    </FormOptionsContainer>
  );
};
