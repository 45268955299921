import * as React from "react";
import { Form } from "../components/form/Form";
import Metadata from "../components/layout/MetaData";
import { GlobalStyles } from "../styles/GlobalStyles";

const FormPage = () => {
  return (
    <>
      <GlobalStyles />
      <Metadata title="Contact us" />
      <Form />
    </>
  );
};

export default FormPage;
