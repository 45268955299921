import styled from "styled-components";
import { defaultTheme } from "../../../styles/theme";

export const FormOptionsContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: ${defaultTheme.spacing.m} ${defaultTheme.spacing.s};
  padding: ${defaultTheme.spacing.s};
  background: ${defaultTheme.colors.darkPurple};
  border-radius: ${defaultTheme.borderRadius.medium};
  z-index: 1;
`;
export const StyledImg = styled.img`
  cursor: pointer;
`;
