import styled from "styled-components";
import { respondTo } from "../../styles/respondTo";
import { defaultTheme } from "../../styles/theme";

export const FormContainer = styled.form`
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  padding: ${defaultTheme.spacing.s} ${defaultTheme.spacing.xxxxl};
  background: ${defaultTheme.colors.primary};
  overflow-y: hidden;

  ${respondTo.mobile`
  padding: ${defaultTheme.spacing.s} 
  `};
`;

export const ButtonContainer = styled.div`
  margin: ${defaultTheme.spacing.l} 0;
  z-index: 1;
`;

export const StyledImg = styled.img`
  cursor: pointer;
  z-index: 1;
`;

export const InputsContainer = styled.div`
  z-index: 1;
  width: 100%;

  ${respondTo.tablet`
  width: 100%;
  margin-right: 0;
  `}
`;

export const ContentContainer = styled.div`
  width: 60%;
  max-width: 800px;
  margin-top: 16px;

  ${respondTo.desktop`
    width:80%;
    margin-top:48px;
  `};

  ${respondTo.tablet`
    width:100%;
    margin-top:0;
  `};
`;

export const BackgroundImg = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 950px;
  left: 50%;
  transform: translateX(-50%);

  ${respondTo.desktop`
  display:none;
  `};
`;
