import * as React from "react";
import styled from "styled-components";

import { Align, Text, Checkbox, RadioInput } from "../../UI";

import { defaultTheme } from "../../../styles/theme";

interface FormOptionsSectionProps {
  title: string;
  options: {
    inputType: string;
    name: string;
    id: string;
    label: string;
    value: string | number;
  }[];
}

const FormOptionsSectionContainer = styled.div`
  margin: ${defaultTheme.spacing.xs};
  flex-grow: 1;
`;

export const FormOptionsSection = ({
  title,
  options,
}: FormOptionsSectionProps) => {
  return (
    <FormOptionsSectionContainer>
      <Align direction="column" align="flex-start" justify="center">
        <Text size="large" color={defaultTheme.colors.accent}>
          {title}
        </Text>
        {options.map((option, index) =>
          option.inputType === "radio" ? (
            <RadioInput
              key={index}
              name={option.name}
              id={option.id}
              label={option.label}
              spacing={defaultTheme.spacing.xs}
              value={option.value}
            />
          ) : (
            <Checkbox
              key={index}
              name={option.name}
              id={option.id}
              label={option.label}
              spacing={defaultTheme.spacing.xs}
              value={option.value}
            />
          )
        )}
      </Align>
    </FormOptionsSectionContainer>
  );
};
