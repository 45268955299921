import * as React from "react";

import { useForm } from "react-hook-form";
import { navigate } from "gatsby";

import { Align } from "../UI/Align";

import {
  FormContainer,
  ButtonContainer,
  StyledImg,
  InputsContainer,
  ContentContainer,
  BackgroundImg
} from "./Form.styled";
import { FormOptions } from "./FormOptions/FormOptions";

import { useText } from "../../common/hooks/useText";
import { Switch, Title, TextInput, Button, Text } from "../UI";

import { useScrollPosition, useViewport } from "../../common/hooks";

import { defaultTheme } from "../../styles/theme";
import { ErrorModal } from "../ErrorModal/ErrorModal";

const arrowBack = require("../../assets/images/arrow-back.svg");
const envelope = require("../../assets/images/envelope.svg");

export const Form = () => {
  const { text } = useText();
  const { register, handleSubmit, errors } = useForm();
  const modalRef = React.useRef<any>(null);

  const formOptions = text([
    {
      title: "contact.formOptions.platforms.title",
      options: [
        {
          inputType: "checkbox",
          label: "Android",
          name: "platforms",
          id: "Android",
          value: "Android"
        },
        {
          inputType: "checkbox",
          label: "IOS",
          name: "platforms",
          id: "IOS",
          value: "IOS"
        },
        {
          inputType: "checkbox",
          label: "Desktop",
          name: "platforms",
          id: "Desktop",
          value: "Desktop"
        },
        {
          inputType: "checkbox",
          label: "Web",
          name: "platforms",
          id: "Web",
          value: "Web"
        }
      ]
    },
    {
      title: "contact.formOptions.technology.title",
      options: [
        {
          inputType: "radio",
          label: "Flutter",
          name: "technology",
          id: "Flutter",
          value: "Flutter"
        },
        {
          inputType: "radio",
          label: "React Native",
          name: "technology",
          id: "React_Native",
          value: "React_Native"
        },
        {
          inputType: "radio",
          label: "contact.formOptions.technology.optimal",
          name: "technology",
          id: "optimal",
          value: "optimal"
        }
      ]
    },
    {
      title: "contact.formOptions.addServices.title",
      options: [
        {
          inputType: "checkbox",
          label: "contact.formOptions.addServices.options.design",
          name: "additional_services",
          id: "design_preparation",
          value: "design_preparation"
        },
        {
          inputType: "checkbox",
          label: "contact.formOptions.addServices.options.cloud",
          name: "additional_services",
          id: "cloud_integration",
          value: "cloud_integration"
        },
        {
          inputType: "checkbox",
          label: "contact.formOptions.addServices.options.api",
          name: "additional_services",
          id: "APIs_integration",
          value: "APIs_integration"
        },
        {
          inputType: "checkbox",
          label: "contact.formOptions.addServices.options.data",
          name: "additional_services",
          id: "database",
          value: "database"
        }
      ]
    }
  ]);

  const { isMobile } = useViewport();

  const { pageYPosition } = useScrollPosition();

  const url = "https://be.geeknauts.com/contact-form";

  const onSubmit = handleSubmit(async () => {
    const formData = new FormData(document.querySelector("form") || undefined);

    const dataToSend = {
      platforms: formData.getAll("platforms").join(", "),
      email: formData.getAll("email").join(", "),
      technology: formData.getAll("technology").join(", "),
      additionalServices: formData.getAll("additional_services").join(", "),
      message: formData.getAll("message").join(", ")
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataToSend)
      });
      if (response.status === 200) {
        navigate("/success");
      } else {
        modalRef.current.openModal();
      }
    } catch (err) {
      modalRef.current.openModal();
    }
  });

  return (
    <>
      <FormContainer onSubmit={e => onSubmit(e)}>
        <Align direction="column" align="center" justify="center">
          <Align direction="row" align="center" justify="space-between">
            <StyledImg
              src={arrowBack}
              onClick={() => {
                navigate("/");
              }}
              alt="go back"
            />
            <Switch />
          </Align>
          <Title size="large" color={defaultTheme.colors.headerDark} align="center" bold>
            {text("contact.sendMessage")}
          </Title>
          <ContentContainer>
            <Align direction="column" align="center" justify="space-between">
              <InputsContainer>
                <Align direction="column" align="center" justify="center">
                  <TextInput
                    name="email"
                    type="text"
                    placeholder={text("contact.inputs.email.placeholder")}
                    rows={1}
                    register={register({
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Wrong email address"
                      }
                    })}
                    error={errors.email}
                  />
                  {errors.email && (
                    <Text size="medium" color="red" bold>
                      {text("contact.inputs.email.error")}
                    </Text>
                  )}
                  <TextInput
                    name="message"
                    type="area"
                    label={text("contact.inputs.message.label")}
                    placeholder={text("contact.inputs.message.placeholder")}
                    rows={8}
                    register={register({ required: true, minLength: 1 })}
                    error={errors.message}
                  />
                  {errors.message && (
                    <Text size="medium" color="red" bold>
                      {text("contact.inputs.message.error")}
                    </Text>
                  )}
                </Align>
              </InputsContainer>
              <Align direction="column" align="center" justify="center">
                <FormOptions direction={isMobile ? "column" : "row"} optionTypes={formOptions} />
                <ButtonContainer>
                  <Button
                    title={text("contact.submitButton")}
                    onPress={() => {
                      if (typeof window !== "undefined") {
                        window.scrollTo(0, 0);
                      }
                    }}
                  />
                </ButtonContainer>
              </Align>
            </Align>
          </ContentContainer>
        </Align>
        <BackgroundImg src={envelope} />
      </FormContainer>
      <ErrorModal ref={modalRef} top={pageYPosition} />
    </>
  );
};
